exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-color-options-js": () => import("./../../../src/pages/color-options.js" /* webpackChunkName: "component---src-pages-color-options-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-coverage-lead-times-js": () => import("./../../../src/pages/coverage-lead-times.js" /* webpackChunkName: "component---src-pages-coverage-lead-times-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ordering-process-js": () => import("./../../../src/pages/ordering-process.js" /* webpackChunkName: "component---src-pages-ordering-process-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-roofstyle-js": () => import("./../../../src/pages/roofstyle.js" /* webpackChunkName: "component---src-pages-roofstyle-js" */),
  "component---src-pages-site-preparation-js": () => import("./../../../src/pages/site-preparation.js" /* webpackChunkName: "component---src-pages-site-preparation-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-popup-js": () => import("./../../../src/pages/thank-you-popup.js" /* webpackChunkName: "component---src-pages-thank-you-popup-js" */),
  "component---src-pages-tx-metal-buildings-js": () => import("./../../../src/pages/tx-metal-buildings.js" /* webpackChunkName: "component---src-pages-tx-metal-buildings-js" */),
  "component---src-templates-category-landing-template-js": () => import("./../../../src/templates/categoryLandingTemplate.js" /* webpackChunkName: "component---src-templates-category-landing-template-js" */),
  "component---src-templates-content-page-template-js": () => import("./../../../src/templates/contentPageTemplate.js" /* webpackChunkName: "component---src-templates-content-page-template-js" */),
  "component---src-templates-financing-page-template-js": () => import("./../../../src/templates/financingPageTemplate.js" /* webpackChunkName: "component---src-templates-financing-page-template-js" */),
  "component---src-templates-thanks-template-js": () => import("./../../../src/templates/thanksTemplate.js" /* webpackChunkName: "component---src-templates-thanks-template-js" */)
}

